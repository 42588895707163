import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { SharedService } from '../../services/shared.service';
import { CustomerService } from 'src/app/modules/customers/customers.service';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss']
})
export class AccountStatementComponent implements OnInit {
  @Input() statementDetails: any;
  @Input() iso_code_2: any;
  @Input() currency_code: any;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() selecteduser: any;
  @Input() country_name: string;
  @Input() fetchedAccountBalance: any;
  @Input() transactionHistory: any[];

  protected unsubscribe$ = new Subject<void>();
  constructor(
    protected router: Router,
    protected toastr: ToastrService,
    protected route: ActivatedRoute,
    private sharedService: SharedService,
    private customerService:CustomerService,
  ) { }

  public months= [
    {key:0, value:'January'},
    {key:1, value:'February'},
    {key:2, value:'March'},
    {key:3, value:'April'},
    {key:4, value:'May'},
    {key:5, value:'June'},
    {key:6, value:'July'},
    {key:7, value:'August'},
    {key:8, value:'September'},
    {key:9, value:'October'},
    {key:10, value:'November'},
    {key:11, value:'December'},
  ]
  merchantCountryDetails: any;
  selectedAccountNumber: string = '';
  isDownload: boolean = false;
  exporting: boolean = false;
  loadingStatement: boolean = false;
  isGeneratingAccountStatement: boolean
  merchant: any
  currentBalance: string
  selectedWalletNumber: string;
  today: string;
  selectedMonth
  totalCount: number
  totalPages: number
  currentPage: number
  pageSize: number = 20
  pageEvent: any
  currentTransactions;
  
  ngOnInit(): void {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    this.today = today.toLocaleDateString(undefined, options);

    this.sharedService.getCountryDetails(this.iso_code_2).subscribe(res => {
      this.merchantCountryDetails = res.data;
    })

    console.log('startDate = ', this.startDate);
    console.log('endDate = ', this.endDate);
  }

  sendAccountStatement = () => {
    this.exporting = true
    const payload = {
      "customer": {
          "name":  this.selecteduser?.reg_name ? this.selecteduser?.reg_name : `${this.selecteduser?.first_name}, ${this.selecteduser?.last_name}`,
          "email": this.selecteduser?.email || this.selecteduser?.director?.email,
          "address": this.selecteduser.address?.line1 || this.selecteduser?.reg_address,
          "city": this.selecteduser.address?.city || this.selecteduser?.town,
          "state": this.selecteduser.address?.state || this.selecteduser?.state,
          "country": this.country_name || this.selecteduser?.currency?.country,
      },
      "startDate": this.startDate,
      "endDate": this.endDate,
      "currency": this.currency_code,
      "alpha2CountryCode": this.selecteduser?.country || this.selecteduser?.currency?.iso_code_2,
      "currentBalance": this.fetchedAccountBalance
    }
    let wallet_no = this.selecteduser?.wallet_no || this.selecteduser?.merchant_default_wallet || this.selecteduser?.associated_wallets[0];
    this.customerService.sendAccountStatement(payload, wallet_no).subscribe(res => {
        this.exporting = false;
        this.toastr.success('Account statement successfully sent to user');
      },
      (err) => {
        // this.toastr.error(err.message, 'ERROR');
        this.exporting = false;
      }
    )
  }

  export() {
    this.exporting = true;
    this.sharedService.exportAccountStatement(
      this.startDate,
      this.endDate,
      this.selecteduser.wallet_no,
      this.iso_code_2,
      this.isDownload
    ).subscribe(res => {
      this.exporting = false;
      this.isDownload = false;
      saveAs(res, 'account-statement.xlsx');
    })
  }

  // UNSUBCRIBE FROM OBSERVABLES
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
